import React from 'react';

function Projects() {
  document.title = 'Projects';

  return (
    <h1>This is projects</h1>
  )
}

export default Projects;
